
const siteURL = "https://testspapi.panelreward.com/api/";   // For Production
const panelSiteURL = "https://apimy.panelreward.com/api/";   // For Production

// const siteURL = "http://localhost:8089/api/";

const appConfig ={
  siteURL : siteURL,
  panelSiteURL : panelSiteURL
}

export default appConfig