
const initialState = {
    requestStarted: false
}

const common = (state = initialState , action) =>{
  switch (action.type) {
     
case 'SET_REQUEST_STARTED' :
return {
  ...state,
    requestStarted: true,

}
case 'SET_REQUEST_COMPLETED' :
  return {
    ...state,
    requestStarted: false
  }
  default:
  return state;
}
};
export default common;
