export const requestStarted = ()=>{
  return {
    type : "SET_REQUEST_STARTED"
  }
}

export const showLoader = ()=>{
  return {
    type : "SET_SHOW_LOADER"
  }
}


export const hideLoader = ()=>{
  return {
    type : "SET_HIDE_LOADER"
  }
}

export const requestCompleted = ()=>{
  return {
    type : "SET_REQUEST_COMPLETED"
  }
}
