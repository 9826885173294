import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addnewParticipant, saveStudyParticipate, participantFailedInScreeners, saveParticipantReply, checkScreenerForQuota } from '../../actions/participantAction';
import LoadingDots from '../Loadingdot/Loadingdots';
import './style.css'
import "./demo.css";
class NewParticipants extends Component {
  constructor(props) {
    super(props)

    this.queryString = window.location.search;

    if (this.queryString !== "") {
      this.tid = this.queryString.split('TID=')[1].split('&')[0];

      this.uid = getUrlParam('UID','Empty');

      this.sid = getUrlParam('SID','Empty');
      this.isTest = getUrlParam('isTest','Empty');
    }


    // this.demoId = [];
    // this.queryId = [];
    // this.optionId = [];
    // this.selectedData = [];
    // this.participantSelectedData = [];
    this.state = {
      sid: this.sid,
      tid: this.tid,
      uid: this.uid,
      isTest : this.isTest,
      finalArray : this.finalArray,

      originParticipant : this.originParticipantDataValue,
      currentQuestion : 0,
      terminateUrl : "",
      isTerminated : false,
      pID : "",
      responseuid : "",
      isSuccessOnServer : false,
      errorMessageFromServer : "",
      demograhicsQuestions : [],
      totalQuestionsData : [],

      correctOptionsList : [],
      currentQueryType : "",
      radioOption : "",
      selectedMultiselectIds : [],


      selectedDropDown : "",
      userRangeGroup : "",
      textData : ""
    }
    this.addnewParticipant = this.addnewParticipant.bind(this);
    this.addParticipantStatusPanelReward = this.addParticipantStatusPanelReward.bind(this);
    this.clicKNext = this.clicKNext.bind(this);

    this.onChangeRadioOption = this.onChangeRadioOption.bind(this);
    this.onMulticheckChange = this.onMulticheckChange.bind(this);
    this.onDropDownChange = this.onDropDownChange.bind(this);
    this.onRangeChange = this.onRangeChange.bind(this);
    this.onTextDataChanged = this.onTextDataChanged.bind(this);
  }

  componentWillMount() {
    if (this.queryString !== "") {
      this.addnewParticipant();
      this.addParticipantStatusPanelReward();
    }
  }

  componentWillReceiveProps( nextProps){

    if(nextProps.participantNewResp && nextProps.participantNewResp.success === true && nextProps.participantNewResp.pID){

      this.setState({
        pID : nextProps.participantNewResp.pID._id
      });
    }
    if( nextProps.participantNewResp && nextProps.participantNewResp.success === true && nextProps.participantNewResp.isDemographics === true ){

      let myAllScreenerData = [];

      if(nextProps.participantNewResp.demographicData && nextProps.participantNewResp.demographicData.length > 0){
        
       myAllScreenerData = nextProps.participantNewResp.demographicData;
      }

      if(nextProps.participantNewResp.demographicRangeData && nextProps.participantNewResp.demographicRangeData.length > 0){
       
        myAllScreenerData = myAllScreenerData.concat(nextProps.participantNewResp.demographicRangeData);
       
      }


      myAllScreenerData.sort(function (a, b) {
              return a.order_no - b.order_no;
             });
            console.log(myAllScreenerData);
      this.setState({
        totalQuestionsData :  myAllScreenerData,
  
      });


    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }


  onDropDownChange(e){

    this.setState({
      selectedDropDown : e.target.value
    });



  }


  onChangeRadioOption(e){
      this.setState({
        radioOption : e.target.value,
        currentQueryType : this.state.totalQuestionsData[this.state.currentQuestion].queryType,
        correctOptionsList : this.state.totalQuestionsData[this.state.currentQuestion].optionId
      });
  }


  onMulticheckChange(e){

    let found = this.state.selectedMultiselectIds.find(element => element  === e.target.value);

    if(found){
      // remove here
      let currentIds = this.state.selectedMultiselectIds.filter(data => data != e.target.value);
      this.setState(
       {
        selectedMultiselectIds : currentIds
      }
     );
    
    }
    else{
          let currentIds = this.state.selectedMultiselectIds;
          currentIds.push(e.target.value);
          this.setState(
            {
             selectedMultiselectIds : currentIds
           }
          );
    }

  }


  onRangeChange(e){
    this.setState({
      userRangeGroup : e.target.value
    });
  }

  onTextDataChanged(e){
    this.setState({
      textData : e.target.value
    });
  }

  addParticipantStatusPanelReward(){
    let participateData = {};
    if(this.queryString.match('$SID$') && this.queryString.match('@UID@') ){

	   participateData.studyId = this.queryString.split('$SID$')[1].split('@UID@')[0];;
   	 participateData.statusId = 0;
   	 let userId = this.queryString.split('@UID@')[1];

	this.props.dispatch(saveStudyParticipate( participateData, userId));
	}
  }

  addnewParticipant() {
    let participantData = {};

    participantData.sid = this.state.sid;
    participantData.tid = this.state.tid;
    participantData.uid = this.state.uid;
    participantData.isTest = this.state.isTest;
    
    this.props.dispatch(addnewParticipant(participantData));
  }



  clicKNext(e){
       
    e.preventDefault();

    let currentQue = this.state.totalQuestionsData[this.state.currentQuestion];

    if(currentQue.queryType === "dropdown"){
      
      if(this.state.selectedDropDown === "" || this.state.selectedDropDown === undefined || this.state.selectedDropDown === "Select"){
        return;
      }

      let correctAnswers =  currentQue.optionId.split(",");
      let found = correctAnswers.find(element => element  === this.state.selectedDropDown);

        if(found){
          // passed
          // save to array for quota check

          if(this.state.currentQuestion ===  this.state.totalQuestionsData.length-1 ){
            // call finish here
            let selectedOptionsData =[];
            selectedOptionsData = this.state.selectedDropDown;
           let data = {};
           data.sid = this.state.sid;
           data.uid = this.state.uid;
           data.tid = this.state.tid;
           data.queryId = currentQue.queryId;
           data.participantId = this.state.pID;
           data.selectedData = selectedOptionsData.toString();

           data.query_text = this.state.totalQuestionsData[this.state.currentQuestion].question;
           let mySeleectedOption =  this.state.totalQuestionsData[this.state.currentQuestion].options.find(data => data._id ===this.state.selectedDropDown)
           data.option_text = mySeleectedOption.optionText;
           data.is_correct = 1;
           data.lang_code = this.state.totalQuestionsData[this.state.currentQuestion].lang_code;
           data.allVariables = this.state.finalArray;
           data.originParticipant = this.state.originParticipant;
          this.props.dispatch(checkScreenerForQuota(data));
         } else{

          this.setState({
            selectedDropDown : ""
           });

          let selectedOptionsData =[];
          selectedOptionsData = this.state.selectedDropDown;
         let data = {};
         data.sid = this.state.sid;
         data.uid = this.state.uid;
         data.tid = this.state.tid;
         data.queryId = currentQue.queryId;
         data.participantId = this.state.pID;
         data.selectedData = selectedOptionsData.toString();
          // call new api
          data.query_text = this.state.totalQuestionsData[this.state.currentQuestion].question;
          let mySeleectedOption =  this.state.totalQuestionsData[this.state.currentQuestion].options.find(data => data._id ===this.state.selectedDropDown)
          data.option_text = mySeleectedOption.optionText;
          data.is_correct = 1;
          data.lang_code = this.state.totalQuestionsData[this.state.currentQuestion].lang_code;
          data.allVariables = this.state.finalArray;
          this.props.dispatch(saveParticipantReply(data));

           this.setState({
                currentQuestion : this.state.currentQuestion+1
               });
              }
          }
          else{
            let selectedOptionsData =[];
            selectedOptionsData = this.state.selectedDropDown;
           let data = {};
           data.sid = this.state.sid;
           data.uid = this.state.uid;
           data.tid = this.state.tid;
           data.queryId = currentQue.queryId;
           data.participantId = this.state.pID;
            data.selectedData = selectedOptionsData.toString();
           this.setState({
            selectedDropDown : ""
           });

           data.query_text = this.state.totalQuestionsData[this.state.currentQuestion].question;
           let mySeleectedOption =  this.state.totalQuestionsData[this.state.currentQuestion].options.find(data => data._id ===this.state.selectedDropDown)
           data.option_text = mySeleectedOption.optionText;
           data.is_correct = 0;
           data.lang_code = this.state.totalQuestionsData[this.state.currentQuestion].lang_code;

           data.allVariables = this.state.finalArray;

           data.originParticipant = this.state.originParticipant;
            this.props.dispatch(participantFailedInScreeners(data))
          }
      
   
    }


    else if(currentQue.queryType === "radio"){ 

      if(this.state.radioOption === "" || this.state.radioOption === undefined){
        return;
      }

      let correctAnswers =  currentQue.optionId.split(",");
      let found = correctAnswers.find(element => element  === this.state.radioOption);
       
      if(found){
        // passed
        // save to array for quota check

        if(this.state.currentQuestion ===  this.state.totalQuestionsData.length-1 ){
          let selectedOptionsData =[];
          selectedOptionsData = this.state.radioOption;
          // call finish here
          let data = {};
          data.sid = this.state.sid;
          data.uid = this.state.uid;
          data.tid = this.state.tid;
          data.queryId =currentQue.queryId;
          data.participantId = this.state.pID;
          data.selectedData = selectedOptionsData.toString();

          data.query_text = this.state.totalQuestionsData[this.state.currentQuestion].question;
          let mySeleectedOption =  this.state.totalQuestionsData[this.state.currentQuestion].options.find(data => data._id ===this.state.radioOption)
          data.option_text = mySeleectedOption.optionText;
          data.is_correct = 1;
          data.lang_code = this.state.totalQuestionsData[this.state.currentQuestion].lang_code;

          data.allVariables = this.state.finalArray;
          data.originParticipant = this.state.originParticipant;
          this.props.dispatch(checkScreenerForQuota(data));
         
       }
       else{

        this.setState({
          radioOption : ""
         });



        let selectedOptionsData =[];
        selectedOptionsData = this.state.radioOption;
        let data = {};
        data.sid = this.state.sid;
        data.uid = this.state.uid;
        data.tid = this.state.tid;
        data.queryId =currentQue.queryId;
        data.participantId = this.state.pID;
        data.selectedData = selectedOptionsData.toString();
        //call new api

        data.query_text = this.state.totalQuestionsData[this.state.currentQuestion].question;
        let mySeleectedOption =  this.state.totalQuestionsData[this.state.currentQuestion].options.find(data => data._id ===this.state.radioOption)
        data.option_text = mySeleectedOption.optionText;
        data.is_correct = 1;
        data.lang_code = this.state.totalQuestionsData[this.state.currentQuestion].lang_code;
        data.allVariables = this.state.finalArray;
        this.props.dispatch(saveParticipantReply(data));


        
         this.setState({
              currentQuestion : this.state.currentQuestion+1
             });
            }
        }
        else{


          this.setState({
            radioOption : ""
           });
          let selectedOptionsData =[];
          selectedOptionsData = this.state.radioOption;
         let data = {};
         data.sid = this.state.sid;
         data.uid = this.state.uid;
         data.tid = this.state.tid;
         data.queryId = currentQue.queryId;
         data.participantId = this.state.pID;          
         data.selectedData = selectedOptionsData.toString();

         data.query_text = this.state.totalQuestionsData[this.state.currentQuestion].question;
         let mySeleectedOption =  this.state.totalQuestionsData[this.state.currentQuestion].options.find(data => data._id ===this.state.radioOption)
         data.option_text = mySeleectedOption.optionText;
         data.is_correct = 0;
         data.lang_code = this.state.totalQuestionsData[this.state.currentQuestion].lang_code;
         data.allVariables = this.state.finalArray;

         data.originParticipant = this.state.originParticipant;
          this.props.dispatch(participantFailedInScreeners(data))
        }
     

    } else


    if(currentQue.queryType === "multiselect"){
      


      if(this.state.selectedMultiselectIds.length > 0){
        
      }
      else{
        return;
      }


      let correctAnswers = [];
       correctAnswers = currentQue.optionId.split(",");

     
        let isScreenerPassed = "1";
        for(let i = 0; i < this.state.selectedMultiselectIds.length; i++ ){
          let found = correctAnswers.find(element => element  === this.state.selectedMultiselectIds[i]);
       
          if(found){
     
          }
          else{
            // redirect to terminate in scrnner
            isScreenerPassed = "0";
          }
        }


        let mySelectedOptions = "";

        for(let i = 0; i < this.state.selectedMultiselectIds.length; i++ ){
         
          let found = currentQue.options.find(element => element._id  === this.state.selectedMultiselectIds[i]);
       
          if(found){
               if(mySelectedOptions === ""){
                mySelectedOptions = found.optionText;
               }
               else{
                mySelectedOptions = mySelectedOptions+ ", " +found.optionText;
               }
          }
          
        }


       
        if(isScreenerPassed === "0"){
            

          this.setState({
            selectedMultiselectIds : []
           });


              let selectedOptionsData =[];
              selectedOptionsData = this.state.selectedMultiselectIds;
              let data = {};
              data.sid = this.state.sid;
              data.uid = this.state.uid;
              data.tid = this.state.tid;
              data.queryId = currentQue.queryId;
              data.participantId = this.state.pID;
              data.selectedData = selectedOptionsData.toString();

              data.query_text = this.state.totalQuestionsData[this.state.currentQuestion].question;
              data.option_text = mySelectedOptions;
              data.is_correct = 0;
              data.lang_code = this.state.totalQuestionsData[this.state.currentQuestion].lang_code;
   

              data.allVariables = this.state.finalArray;

              data.originParticipant = this.state.originParticipant;
               this.props.dispatch(participantFailedInScreeners(data))


        }else{

          if(this.state.currentQuestion ===  this.state.totalQuestionsData.length-1 ){
            // call finish here
            let selectedOptionsData =[];
            selectedOptionsData = this.state.selectedMultiselectIds;
            let data = {};
            data.sid = this.state.sid;
            data.uid = this.state.uid;
            data.tid = this.state.tid;
            data.queryId = currentQue.queryId;
            data.participantId = this.state.pID;
            data.selectedData = selectedOptionsData.toString();


            data.query_text = this.state.totalQuestionsData[this.state.currentQuestion].question;
            data.option_text = mySelectedOptions;
            data.is_correct = 1;
            data.lang_code = this.state.totalQuestionsData[this.state.currentQuestion].lang_code;
 
            data.allVariables = this.state.finalArray;
            data.originParticipant = this.state.originParticipant;
            this.props.dispatch(checkScreenerForQuota(data));
         }
         else{
          let selectedOptionsData =[];
          selectedOptionsData = this.state.selectedMultiselectIds;
          let data = {};
          data.sid = this.state.sid;
          data.uid = this.state.uid;
          data.tid = this.state.tid;
          data.queryId =currentQue.queryId;
          data.participantId = this.state.pID;
          data.selectedData = selectedOptionsData.toString();
          // call new api


          data.query_text = this.state.totalQuestionsData[this.state.currentQuestion].question;
          data.option_text = mySelectedOptions;
          data.is_correct = 1;
          data.lang_code = this.state.totalQuestionsData[this.state.currentQuestion].lang_code;

          data.allVariables = this.state.finalArray;
          this.props.dispatch(saveParticipantReply(data));

          this.setState({
            selectedMultiselectIds : []
           });

          this.setState({
            currentQuestion : this.state.currentQuestion+1
           });
        }
      }
       }

    

    else if(currentQue.queryType === "range"){
    


      let decimal=  /^[0-9]+$/; 

      if(this.state.userRangeGroup === "" || this.state.userRangeGroup === undefined){
        alert("Please enter in given input box.");
        return;
      }
      else 
      if(!this.state.userRangeGroup.match(decimal)){
        
        alert("Please enter whole number in given input box.");
        return;
        
    }



      let  userRangeGroup = parseInt(this.state.userRangeGroup);

  
      let correctAnswers = currentQue.rangeOptions;
      let isRangePassed = "1";
      for(let i = 0; i < correctAnswers.length; i++){
          if(parseInt(correctAnswers[i].ageFrom) <= userRangeGroup && userRangeGroup <= parseInt( correctAnswers[i].ageTo) ){
            isRangePassed = "1";
            break;
          }
          else{
            isRangePassed = "0";
          }
      }





    
      if(isRangePassed === "0"){
        // terminate here 


        this.setState({
          userRangeGroup : ""
         });
        
         let selectedOptionsData =[];
         selectedOptionsData = this.state.userRangeGroup;
         // call finish here
         let data = {};
         data.sid = this.state.sid;
         data.uid = this.state.uid;
         data.tid = this.state.tid;
         data.queryId =currentQue.queryId;
         data.selectedData = selectedOptionsData.toString();
         data.participantId = this.state.pID;
         data.query_text = this.state.totalQuestionsData[this.state.currentQuestion].question;
         data.option_text = selectedOptionsData.toString();
         data.is_correct = 0;
         data.lang_code = this.state.totalQuestionsData[this.state.currentQuestion].lang_code;



         data.allVariables = this.state.finalArray;

         data.originParticipant = this.state.originParticipant;

         this.props.dispatch(participantFailedInScreeners(data))

      }
      else{

        if(this.state.currentQuestion ===  this.state.totalQuestionsData.length-1 ){
          // call finish here
          let selectedOptionsData =[];
         selectedOptionsData = this.state.userRangeGroup;
         let data = {};
         data.sid = this.state.sid;
         data.uid = this.state.uid;
         data.tid = this.state.tid;
         data.queryId =currentQue.queryId;
         data.participantId = this.state.pID;
         data.selectedData = selectedOptionsData.toString();
         data.query_text = this.state.totalQuestionsData[this.state.currentQuestion].question;
         data.option_text = selectedOptionsData.toString();
         data.is_correct = 1;
         data.lang_code = this.state.totalQuestionsData[this.state.currentQuestion].lang_code;
         data.allVariables = this.state.finalArray;
         data.originParticipant = this.state.originParticipant;
          this.props.dispatch(checkScreenerForQuota(data));
       }
       else{

        let selectedOptionsData =[];
        selectedOptionsData = this.state.userRangeGroup;
        let data = {};
        data.sid = this.state.sid;
        data.uid = this.state.uid;
        data.tid = this.state.tid;
        data.queryId =currentQue.queryId;
        data.participantId = this.state.pID;
        data.selectedData = selectedOptionsData.toString();
        // call new api
        data.query_text = this.state.totalQuestionsData[this.state.currentQuestion].question;
        data.option_text = selectedOptionsData.toString();
        data.is_correct = 1;
        data.lang_code = this.state.totalQuestionsData[this.state.currentQuestion].lang_code;
        data.allVariables = this.state.finalArray;
        this.props.dispatch(saveParticipantReply(data));


        this.setState({
          userRangeGroup : ""
         });

        this.setState({
          currentQuestion : this.state.currentQuestion+1
         });
      }
     }



      

    }
    else if(currentQue.queryType === "text"){

      if(this.state.textData === "" || this.state.textData === undefined){
        alert("Please enter in given input box.");
        return;
      }



      if(this.state.currentQuestion ===  this.state.totalQuestionsData.length-1 ){
        // call finish here
        let selectedOptionsData =[];
        selectedOptionsData = this.state.textData;
        let data = {};
        data.sid = this.state.sid;
        data.uid = this.state.uid;
        data.tid = this.state.tid;
        data.queryId =currentQue.queryId;
        data.participantId = this.state.pID;
        data.selectedData = selectedOptionsData.toString();

        data.query_text = this.state.totalQuestionsData[this.state.currentQuestion].question;
        data.option_text = selectedOptionsData.toString();
        data.is_correct = 1;
        data.lang_code = this.state.totalQuestionsData[this.state.currentQuestion].lang_code;

        data.allVariables = this.state.finalArray;
        data.originParticipant = this.state.originParticipant;
        this.props.dispatch(checkScreenerForQuota(data))
     }
     else{

      let selectedOptionsData =[];
      selectedOptionsData = this.state.textData;
      let data = {};
      data.sid = this.state.sid;
      data.uid = this.state.uid;
      data.tid = this.state.tid;
      data.queryId =currentQue.queryId;
      data.participantId = this.state.pID;
      data.selectedData = selectedOptionsData.toString();
      //call new api

      data.query_text = this.state.totalQuestionsData[this.state.currentQuestion].question;
         data.option_text = selectedOptionsData.toString();
         data.is_correct = 1;
         data.lang_code = this.state.totalQuestionsData[this.state.currentQuestion].lang_code;
         data.allVariables = this.state.finalArray;
      this.props.dispatch(saveParticipantReply(data));

      this.setState({
        textData : ""
       });

      this.setState({
        currentQuestion : this.state.currentQuestion+1
       });
     }

    }
  
  }


  render() {

  
    let showMessage = "";
    let url="";

    let  showQuestions = "";
    let submitButton = "";
    let showOptions="";

    let showHtml = "";
    let totalQuestionsData = [];
    
    let showParticipantPage = "";
     if(this.props.participantNewResp){
      if( this.props.participantNewResp && this.props.participantNewResp.success === true && this.props.participantNewResp.isDemographics === true ){


        if(this.props.participantNewResp.demographicData && this.props.participantNewResp.demographicData.length > 0){
          totalQuestionsData = this.props.participantNewResp.demographicData;
        }

        if(this.props.participantNewResp.screenerRangeData && this.props.participantNewResp.screenerRangeData.length > 0){
          totalQuestionsData =  totalQuestionsData.concat(this.props.participantNewResp.screenerRangeData)
        }
    
     
        // totalQuestionsData.sort(function (a, b) {
        //   return a.order_no - b.order_no;
        // });


        // console.log(totalQuestionsData);
   


     if(this.state.totalQuestionsData[this.state.currentQuestion].queryType === "text"){

      let questionText = this.state.totalQuestionsData[this.state.currentQuestion].question; 
      
      questionText = questionText.trim();
      // showQuestions = <div>
      //                  <span style={{fontSize: "18px"}}><b>{this.state.currentQuestion+1}/{this.state.totalQuestionsData.length}</b></span><br/>
      //                  <span style={{fontSize: "18px" , color : 'black', marginTop : "16px"}}><b>{questionText.charAt(0).toUpperCase() + questionText.slice(1)} </b></span>
      //                </div>

      // showOptions =  <div>
      //                       <div class="formgroup rltv-pos" >
      //                               <input className="form-controll "  name="Name" value = {this.state.textData} onChange = {this.onTextDataChanged} type="text"></input>
      //                             </div>
      //                       </div>


      showOptions =   <section className="align-self-center">
                      <div className="question-title">
                            <h3><b>{this.state.currentQuestion+1}/{this.state.totalQuestionsData.length} {").  "} {questionText.charAt(0).toUpperCase() + questionText.slice(1)}</b></h3>
                      </div>
                      <div className="d-flex justify-content-left">
                          <div className="flex-column">
                              <input className="form-input" type="text"  name="Name" value = {this.state.textData} onChange = {this.onTextDataChanged} placeholder="Enter here"/>
                          </div>
                      </div>
                  </section>



     } else if(this.state.totalQuestionsData[this.state.currentQuestion].queryType === "radio"){
      let questionText = this.state.totalQuestionsData[this.state.currentQuestion].question; 
      
      questionText = questionText.trim();
      // showQuestions = <div>
      //                  <span style={{fontSize: "18px"}}><b>{this.state.currentQuestion+1}/{this.state.totalQuestionsData.length}</b></span><br/>
      //                  <span style={{fontSize: "18px" , color : 'black', marginTop : "16px"}}><b>{questionText.charAt(0).toUpperCase() + questionText.slice(1)} </b></span>
      //                </div>
      
      // showOptions = this.state.totalQuestionsData[this.state.currentQuestion].options.map((item, i) => {
      //       if(this.state.radioOption === ""){
      //         return  <label class="container">
      //         <input type="radio"  value={item._id} checked={(this.state.radioOption == item._id)} name="radio" onChange={this.onChangeRadioOption}/><span id="optiontext">{item.optionText}</span>
      //         <span class="checkmarkradio" ></span>
      //           </label>
      //       }else{
      //         return  <label class="container">
      //         <input type="radio" value={item._id}  checked={(this.state.radioOption ==  item._id)} name="radio" onChange={this.onChangeRadioOption}/><span id="optiontext">{item.optionText}</span>
      //         <span class="checkmarkradio" ></span>
      //           </label>
      //       }
      //     });
                

      showOptions =   <section className="align-self-center">
                              <div className="question-title">
                                <h3><b>{this.state.currentQuestion+1}/{this.state.totalQuestionsData.length} {").  "} {questionText.charAt(0).toUpperCase() + questionText.slice(1)}</b></h3>
                              </div>
                              <div className="d-flex justify-content-left">
                                  <div className="flex-column">
                                  { 
                                    this.state.totalQuestionsData[this.state.currentQuestion].options.map((item, i) => (
                                                    <label className="custom-input" key={i}>{item.optionText}
                                                        <input type="radio" value={item._id} checked={(this.state.radioOption == item._id)} name="radio" onChange={this.onChangeRadioOption}/>
                                                        <span className="radiomark"/>
                                                    </label>
                                      
                                                ))
                                  }
                                  </div>
                              </div>
                        </section>

  
    } else if(this.state.totalQuestionsData[this.state.currentQuestion].queryType === "dropdown"){
      

      let questionText = this.state.totalQuestionsData[this.state.currentQuestion].question; 
      
      questionText = questionText.trim();
    //   showQuestions = <div>
    //                    <span style={{fontSize: "18px"}}><b>{this.state.currentQuestion+1}/{this.state.totalQuestionsData.length}</b></span><br/>
    //                    <span style={{fontSize: "18px" , color : 'black', marginTop : "16px"}}><b>{questionText.charAt(0).toUpperCase() + questionText.slice(1)} </b></span>
    //                  </div>

    //  let  dropDownOptions = this.state.totalQuestionsData[this.state.currentQuestion].options.map((item, i) => {
    //     return     <option value = {item._id} >{item.optionText}</option>
    //         });

    //         showOptions =    <div className = "formgroup">
    //                   <div class="formgroup-col-6  rltv-pos">
    //                       <label>Country*</label>
    //                       <select className="form-controll" style={{width:"100%", padding: "2px"}}   name="country"  value={this.state.country} onChange={this.onCountryChange}>
    //                       <option  value = ""> Select</option>
    //                       {dropDownOptions}
    //                       </select>
    //           </div>
    //           </div>
    
         let  dropDownOptions = this.state.totalQuestionsData[this.state.currentQuestion].options.map((item, i) => {
                              return <option value = {item._id} >{item.optionText}</option>
            });

    showOptions =   <section className="align-self-center">
                        <div className="question-title">
                          <h3><b>{this.state.currentQuestion+1}/{this.state.totalQuestionsData.length} {").  "} {questionText.charAt(0).toUpperCase() + questionText.slice(1)}</b></h3>
                        </div>
                        <div className="d-flex justify-content-left">
                            <div className="flex-column">
                            { 
                              this.state.totalQuestionsData[this.state.currentQuestion].options.map((item, i) => (
                                <select className="custom-select" name="country"  value={this.state.country} onChange={this.onCountryChange}>
                                 {dropDownOptions}
                                </select>      
                               ))
                            }
                            </div>
                        </div>
                      </section>
                                

    } else if(this.state.totalQuestionsData[this.state.currentQuestion].queryType === "multiselect"){
      
      let questionText = this.state.totalQuestionsData[this.state.currentQuestion].question; 
      
      questionText = questionText.trim();
      // showQuestions = <div>
      //                  <span style={{fontSize: "18px"}}><b>{this.state.currentQuestion+1}/{this.state.totalQuestionsData.length}</b></span><br/>
      //                  <span style={{fontSize: "18px" , color : 'black', marginTop : "16px"}}><b>{questionText.charAt(0).toUpperCase() + questionText.slice(1)} </b></span>
      //                </div>
      
      // showOptions = this.state.totalQuestionsData[this.state.currentQuestion].options.map((item, i) => {
        
      //   let found = this.state.selectedMultiselectIds.find(element => element  === item._id);
      //   if(found){
      //     return    <label class="containersec"> 
          
      //                 {item.optionText}
      //                   <input type="checkbox" checked = {true} name ={item.optionText} value={item._id} key={item._id} onChange={this.onMulticheckChange}/>
      //                   <span class="checkmark"></span>
      //               </label>
      //   } else {
      //       return  <label class="containersec"> 
      //                 {item.optionText}
      //                 <input type="checkbox" checked = {false} name ={item.optionText} value={item._id} key={item._id} onChange={this.onMulticheckChange}/>
      //                 <span class="checkmark"></span>
      //               </label>
      //   }
      //   });


        
    showOptions =   <section className="align-self-center">
                      <div className="question-title">
                        <h3><b>{this.state.currentQuestion+1}/{this.state.totalQuestionsData.length} {").  "} {questionText.charAt(0).toUpperCase() + questionText.slice(1)}</b></h3>
                      </div>
                      <div className="d-flex justify-content-left">
                          <div className="flex-column">
                          { 
                            this.state.totalQuestionsData[this.state.currentQuestion].options.map((item, i) => (
                              
                              <label className="custom-input" key={i}>{item.optionText}
                              <input type="checkbox" checked = {this.state.selectedMultiselectIds.find(element => element  === item._id)} name ={item.optionText} value={item._id} key={item._id} onChange={this.onMulticheckChange} />
                              <span className="checkmark" />
                              </label>      
                            ))
                          }
                          </div>
                      </div>
                    </section>


    } else if(this.state.totalQuestionsData[this.state.currentQuestion].queryType === "range"){
      let questionText = this.state.totalQuestionsData[this.state.currentQuestion].question; 
      
      questionText = questionText.trim();
      // showQuestions = <div>
      //                  <span style={{fontSize: "18px"}}><b>{this.state.currentQuestion+1}/{this.state.totalQuestionsData.length}</b></span><br/>
      //                  <span style={{fontSize: "18px" , color : 'black', marginTop : "16px"}}><b>{questionText.charAt(0).toUpperCase() + questionText.slice(1)} </b></span>
      //                </div>
      // showOptions =  <div>
      //                         <div class="formgroup rltv-pos" >
      //                               <input className="form-controll "  name="Name" onChange = {this.onRangeChange} value = {this.state.userRangeGroup} type="text" placeholder="Enter here"/>
      //                             </div>
      //               </div>

      showOptions =   <section className="align-self-center">
                          <div className="question-title">
                                <h3><b>{this.state.currentQuestion+1}/{this.state.totalQuestionsData.length} {").  "} {questionText.charAt(0).toUpperCase() + questionText.slice(1)}</b></h3>
                          </div>
                          <div className="d-flex justify-content-left">
                              <div className="flex-column">
                                  <input className="form-input" type="text"  name="Name" onChange = {this.onRangeChange} value = {this.state.userRangeGroup} placeholder="Enter here"/>
                              </div>
                          </div>
                      </section>

    }






      // if(this.state.currentQuestion === this.state.totalQuestionsData.length-1){
      
      //    submitButton = <button id="nextpre" onClick={this.onFinish}><span>Finish</span></button>
      // }else{
         submitButton = <button className="list-btn" onClick={this.clicKNext}><span>Next</span></button>
    // }

   

    // showHtml =    <div id="questionsform">
    //                     <div id="questiontext">
    //                     {showQuestions}
    //                     </div>
    //                     <div id="optionstext">
    //                   {showOptions}
    //                 </div>
    //                 <hr/>
    //                 <div id="buttons">
    //                         {submitButton}
    //                 </div>
    //             </div>



    showHtml = <div className="participant-container">
                  <div className="demographics-container">
                      <div className="card align-self-center">
                          <div className="card-header">
                              <h4 className="text-center">
                                  <b>Take Your Survey</b>
                              </h4>
                                <div className="card-body">
                                  {showOptions}
                                </div>

                              <div className="card-footer">
                              <button className="list-btn float-right" onClick={this.clicKNext}>Next</button>
                             </div>
                          </div>
                      </div>
                  </div>
                </div>

                  if(this.props.participantNewResp && this.props.participantNewResp.success === true){
                    showMessage =  <div>
                   
                        {/* <h3>*Please don't refresh the page.</h3><br/><br/> */}
                          {/* <div id="rightcontainer"  className="subcontainers">
                          <h3>*Please don't refresh the page.</h3> */}
                          {showHtml}
                        {/* </div> */}
                  </div>
                  }else{
                    showMessage = ""
                  }



      }else if(this.props.participantNewResp.studiesStatus === "live"  && this.props.participantNewResp.uniqueLink === false){
           if(this.props.participantNewResp.firstPartyUrl.firstPartyUrl.includes("SID=<sid>")){
            let url1 = this.props.participantNewResp.firstPartyUrl.firstPartyUrl.replace("<sid>", this.state.sid );
            url = url1.replace("<pid>",this.props.participantNewResp.pID );
             window.open(url, '_self');
           }else{
            url = this.props.participantNewResp.firstPartyUrl.firstPartyUrl.replace("<pid>",this.props.participantNewResp.pID );
             window.open(url, '_self');
           }
             
         }else if (this.props.participantNewResp.studiesStatus === "live"  && this.props.participantNewResp.uniqueLink === true){
          if(this.props.participantNewResp.firstPartyUrl.firstPartyUrl.includes("SID=<sid>")){
            let url1 = this.props.participantNewResp.firstPartyUrl.firstPartyUrl.replace("<sid>", this.state.sid );
            url =  url1+ this.props.participantNewResp.pID._id;
             window.open(url, '_self');
           }else{
             url = this.props.participantNewResp.firstPartyUrl.firstPartyUrl + this.props.participantNewResp.pID._id;
             window.open(url, '_self');
           }
         }else if(this.props.participantNewResp.studiesStatus === "completed"  && this.props.participantNewResp.uniqueLink === true){
          showMessage = 
          <div className="showmsg">
                          <h3 >Sorry,  we have no more unique link survey</h3>
                        </div>
         } else if(this.props.participantNewResp.isDuplicate === true){
          showMessage = 
          <div className="showmsg">
                          <h3 >{this.props.participantNewResp.message}</h3>
                        </div>
          }
         else if(this.props.participantNewResp.studiesStatus === "completed"){
            showMessage = 
            <div className="showmsg">
                          <h3 >Sorry, our servey is completed</h3>
                        </div>
         }else if(this.props.participantNewResp.studiesStatus === "pause"){
            showMessage = 
            <div className="showmsg">
                          <h3 > Sorry, our servey is temporarily unavailable</h3>
                        </div>
         }else if(this.props.participantNewResp.studiesStatus === "deleted"){
            showMessage = 
            <div className="showmsg">
                          <h3 >Sorry, the survey you are looking for is unavailable</h3>
                        </div>
         }else if(this.props.participantNewResp.ipFailed == true){
            if(this.props.participantNewResp.terminateUrl.terminateUrl.includes("<uid>")){
            url = this.props.participantNewResp.terminateUrl.terminateUrl.split("<uid>")[0] + this.props.participantNewResp.uid + this.props.participantNewResp.terminateUrl.terminateUrl.split("<uid>")[1];
            }else{
            url = this.props.participantNewResp.terminateUrl.terminateUrl;
            }
            window.open(url, '_self');
         }else if(this.props.participantNewResp.vendorQuotaFailed == true){
          showMessage = <div className="showmsg">
                          <h3 >Thank you for your time. GNN has achieved target from this Panel.</h3>
                        </div>
       }else if(this.props.participantNewResp.demoFailed == true){
          if(this.props.participantNewResp.terminateUrl.terminateUrl.includes("<uid>")){
          url = this.props.participantNewResp.terminateUrl.terminateUrl.split("<uid>")[0] + this.props.participantNewResp.uid + this.props.participantNewResp.terminateUrl.terminateUrl.split("<uid>")[1];
          }else{
          url = this.props.participantNewResp.terminateUrl.terminateUrl;
          }
          window.open(url, '_self');
       }
     }

     return (
         <div  id="main">
             {/* <h1 className="participant-header">Welcome at Panelreward Participant Page</h1> */}
             <div className="participant-container">
             <div className="loading" style={{position: "relative"}}>{
          this.props.requestStarted
              ?
              <div style={{ marginLeft: '40%' }}><LoadingDots interval={100} dots={5} /></div>
              :
              false
          }</div>
          {/* <div className="showmsg"> */}
              {showMessage}
              {/* </div> */}
             </div>
         </div>
     );
  }
}


function getUrlParam(parameter, defaultvalue){
  var urlparameter = defaultvalue;
  if(window.location.href.indexOf(parameter) > -1){
      urlparameter = getUrlVars()[parameter];
      }
  return urlparameter;
}


function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
      vars[key] = value;
  });
  return vars;
}

const mapStateToProps = (state) => {
  return {
    participantNewResp: state.store.participantNewResp,
    resultFailedScreener : state.store.resultFailedScreener,
    
  }
}

export default connect(mapStateToProps)(NewParticipants);