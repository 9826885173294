const initialState = {
  updateParticipantResp : ""
  }
  
  const store = (state = initialState , action) =>{
    switch (action.type) {

      case 'SET_NEW_PARTICIPANT_RESPONSE':
			return {
				...state,
				participantNewResp: action.participantNewResp,
            }

      case "SET_FAILED_IN_SCREENER":
        return {
          ...state,
          resultFailedScreener : action.resultFailedScreener,
          participantResult : null
        }
        default:
            return state;
          }
  };
  export default store;
  