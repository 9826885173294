import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NewParticipants from './components/participantsFolder'

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {

    }

  }

  render() {
    let viewHtml ="";

    viewHtml = <Router>
      <div>
      <Route component={NewParticipants} exact path="/:key?" />
      </div>
    </Router>

    return (
      <div className="App">
        <header className="App-header">
        {viewHtml}
        </header>
      </div>
    );
  }
}

export default App;
