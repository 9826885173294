 import appConfig from  './apiConfig';


 //Api For Add Participant
 export const addnewParticipantApi = (data) => {
	let participantData = JSON.stringify(data);
	const headers = Object.assign({
		'Content-Type': 'application/json',
	});
	const request = new Request(appConfig.siteURL + 'participantsnewNew', {
		method: "POST",
		headers: headers,
		body: participantData
	});

	return fetch(request).then(response => {
		return response.json().then(result => {
			return result;
		});
	}).catch(error => {
		console.log("Create participant", error)
		return error;
	});
}


export const saveStudyParticipateApi =( data, userId)=>{
	let participate =  JSON.stringify(data);
  
  const headers = Object.assign({'Content-type':'application/json',
								 'Accept':'*/*'});
  const request= new Request( appConfig.panelSiteURL+'study-participate/'+userId, {
	method : "POST",
	headers : headers,
	body : participate
  });
  
  return fetch(request).then(response => {
	  return  response.json().then(studyDataResponse => {
		return studyDataResponse;
	  });
	}).catch(error => {
	 return error;
   });
  }

  export const participantFailedInScreenersApi = (data) => {

	let participantData = JSON.stringify(data);
	const headers = Object.assign({
	'Content-Type': 'application/json',
	});
		const request = new Request(appConfig.siteURL + 'participantFailedInScreenersRouter', {
		method: "POST",
		headers: headers,
		body: participantData
	});

	return fetch(request).then(response => {
		return response.json().then(result => {
			return result;
		});
	}).catch(error => {
		console.log("Create participant", error)
		return error;
		});
	}


	

export const saveParticipantReplyApi = (data) => {
    let DemoMappingData = JSON.stringify(data);
    const headers = Object.assign({
      'Content-Type': 'application/json',
    });
    const request = new Request(appConfig.siteURL + 'saveParticipantReply/', {
      method: "POST",
      headers: headers,
      body: DemoMappingData
    });
  
    return fetch(request).then(response => {
      return response.json().then(result => {
        return result;
      });
    }).catch(error => {
      console.log("Create third party", error)
      return error;
    });
  }

  export const checkScreenerForQuotaApi = (data) => {

  let participantData = JSON.stringify(data);
   const headers = Object.assign({
	   'Content-Type': 'application/json',
   });
   const request = new Request(appConfig.siteURL + 'checkScreenerForQuotaRouter', {
	   method: "POST",
	   headers: headers,
		body: participantData
   });
  
   return fetch(request).then(response => {
	   return response.json().then(result => {
		   return result;
	   });
   }).catch(error => {
	   console.log("Create participant", error)
	   return error;
   });
  }