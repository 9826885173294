import {combineReducers} from 'redux';
import store from './store';
import common from './common'

const rootReducer = combineReducers({
    store,
    common
});

export default rootReducer;
