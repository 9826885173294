
import { addnewParticipantApi , saveStudyParticipateApi, participantFailedInScreenersApi, saveParticipantReplyApi, checkScreenerForQuotaApi} from '../api/participantApi';
import { requestStarted, requestCompleted } from "./common"

export const addnewParticipant = (data) => {
	return dispatch => {
		dispatch(requestStarted());
		addnewParticipantApi(data).then((result) => {
			dispatch(requestCompleted());
			dispatch(setnewParticipant(result));

		});
	}
}

export const setFailedInScreener =(resultFailedScreener) => {
    return {
      type : "SET_FAILED_IN_SCREENER",
      resultFailedScreener
    }
  }

export const saveStudyParticipate=(data, userId) =>{
	return dispatch =>{
	  //dispatch(startSpinner());
	  saveStudyParticipateApi(data, userId).then((result) =>{
		//dispatch(endSpinner());
	//	 dispatch(setGetStudiesAgain(result));
	  });
	}
  }

export const setnewParticipant = (participantNewResp) => {
	return {
		type: "SET_NEW_PARTICIPANT_RESPONSE",
		participantNewResp

	}
}

//participantFailedInScreeners
export const participantFailedInScreeners = (data) => {
	return dispatch => {
		//dispatch(requestStarted());
		participantFailedInScreenersApi(data).then((result) => {
				//dispatch(setFailedInScreener(result));
			dispatch(setnewParticipant(result));
		});
	}
}

export const saveParticipantReply = (data) => {
	return dispatch => {
		saveParticipantReplyApi(data).then((result) => {

			// dispatch(setparticipantReplyRes(result));
		});
	}
}

export const checkScreenerForQuota = (data) => {
	return dispatch => {
		//dispatch(requestStarted());
		checkScreenerForQuotaApi(data).then((result) => {
			dispatch(setnewParticipant(result));
		});
	}
}